<template>
  <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" right split
    @click="submit(defaultOption)" :disabled="loading" v-bind="$attrs">
    <template #button-content>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      {{ $t(buttonOptionLabel[defaultOption - 1]) }}
    </template>
    <b-dropdown-item v-for="btn in options" :key="`btn-option-${btn.id}`" @click="submit(btn.id)">
      {{ $t(btn.label) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
  import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";

  export default {
    components: {
      BDropdown,
      BDropdownItem,
      BSpinner,
    },
    directives: {
      Ripple,
    },
    props: {
      resource: {
        type: String,
        required: true,
      },
      route: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      hideCreate: {
        type: Boolean,
        default: false,
      },
      customCreateRoute: {
        type: String,
        default: "",
      },
      hideUpdate: {
        type: Boolean,
        default: false,
      },
      customUpdateRoute: {
        type: String,
        default: "",
      },
      isPreviewable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      options() {
        const options = this.buttonOptions.filter((element) => {
          if (element.id != this.defaultOption) {
            if (!element.permission) {
              return element;
            }

            if (this.hideCreate && element.permission == "create") {
              return false;
            }

            if (this.hideUpdate && element.permission == "update") {
              return false;
            }

            if (this.$can(element.permission, this.resource)) {
              return element;
            }
          }
        });

        return options;
      },
      buttonOptions() {
        let options = [
          {
            id: 1,
            label: "button.saveAndBack",
          },
          {
            id: 2,
            label: "button.saveAndEdit",
            permission: "update",
          },
          {
            id: 3,
            label: "button.saveAndNew",
            permission: "create",
          },
        ];

        if (this.isPreviewable) {
          options.push({
            id: 4,
            label: "button.saveAndPreview",
            permission: "read",
          });
        }

        return options;
      },
    },
    data() {
      return {
        defaultOption: 1,
        buttonOptionLabel: [
          "button.saveAndBack",
          "button.saveAndEdit",
          "button.saveAndNew",
          "button.saveAndPreview",
        ],
      };
    },
    mounted() {
      const defaultOption = localStorage.getItem("btnSave");
      this.defaultOption = defaultOption ? Number(defaultOption) : 1;
      if (this.defaultOption == 4 && !this.isPreviewable) {
        this.defaultOption = 1;
      }
      this.checkPermission();
    },
    methods: {
      checkPermission() {
        this.buttonOptions.forEach((element) => {
          if (element.id == this.defaultOption && element.permission) {
            if (!this.$can(element.permission, this.resource)) {
              this.defaultOption = 1;
              localStorage.setItem("btnSave", 1);
            }
          }
        });
      },
      submit(type) {
        if (this.loading) {
          return;
        }

        this.$emit("submit", type);
      },
      confirm(type) {
        this.$bvModal
          .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("button.save"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$emit("save", type);
            }
          });
      },
      afterSave(type, id) {
        this.defaultOption = type ? type : this.defaultOption;
        localStorage.setItem("btnSave", this.defaultOption);

        switch (this.defaultOption) {
          case 1:
            this.$router.push({
              name: `list-${this.route}`,
            });
            break;
          case 2:
            if (this.customUpdateRoute) {
              this.$router.push({
                name: this.customUpdateRoute,
                params: { id: id },
              });
            } else {
              this.$router.push({
                name: `update-${this.route}`,
                params: { id: id },
              });
            }
            break;
          case 3:
            if (this.customCreateRoute) {
              this.$router.push({
                name: this.customCreateRoute,
              });
            } else {
              this.$router.push({
                name: `create-${this.route}`,
              });
            }
            break;
          case 4:
            this.$router.push({
              name: `view-${this.route}`,
              params: { id: id },
            });
            break;
        }
      },
    },
  };
</script>

<style scoped></style>
